import { Link, useLocation } from "@remix-run/react";
import dayjs from "dayjs";
import isEmpty from "lodash/isEmpty";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Icon } from "~/components/atoms/Icons";
import FacebookLightIcon from "~/components/atoms/Icons/FacebookLightIcon";
import ImageResponsive from "~/components/atoms/ImageResponsive";
import Typo from "~/components/atoms/Typo";
import SalesTeamInfoFooter from "~/components/molecules/SalesTeamInfoFooter";
import { ILocation } from "~/entities/location";
import { IMediaImageUrls } from "~/entities/media";
import { IAgencySocial } from "~/types";
import { cn } from "~/utilities/cn";
import { COMPANY_MENU, SEGMENT_MENU } from "~/utilities/config/footerConfig";
import { SOCIAL_LIGHT_ICONS } from "~/utilities/constants/socialIcons";
import { ETypoColor } from "~/utilities/enums/Colors";
import { Slug } from "~/utilities/enums/Slug";
import { ESocialLinks } from "~/utilities/enums/socialLinks";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";
import { formatPhoneNumber } from "~/utilities/helpers/formatPhoneNumber";

export interface FooterProps {
  logo?: IMediaImageUrls;
  phoneNumber?: string;
  socialLinks?: IAgencySocial[];
  siteTitle?: string;
  siteDescription?: string;
  domain?: string;
  locations?: ILocation[];
  disclaimer?: string;
}

export default function Footer({
  logo,
  phoneNumber = "",
  socialLinks = [],
  siteTitle = "",
  siteDescription = "",
  disclaimer = "",
  domain = "",
  locations,
}: FooterProps) {
  const { t } = useTranslation();
  const formattedYear = dayjs().format("YYYY");
  const location = useLocation();
  const isProjectDetailPage = /^\/projects\/[^/]+$/.test(
    location?.pathname || ""
  );
  const [category, setCategory] = useState<string>(t("Company"));

  useEffect(() => {
    function getTextBeforePipe(input: string): string {
      const index = input.indexOf("|");
      if (index === -1) {
        return input; // Return the whole string if '|' is not found
      }
      return input.substring(0, index).trim();
    }

    if (!isEmpty(siteTitle)) {
      const getCategory = getTextBeforePipe(siteTitle);
      setCategory(getCategory);
    }
  }, [siteTitle]);

  const renderCompanyMenu = useMemo(
    () => (
      <div className="space-y-6">
        <Typo
          className="font-bold"
          variant={ETypoVariant.BODY_TITLE_16}
          color={ETypoColor.FOOTER}
          tag={ETypoTag.P}
        >
          {category ?? t("footer.company")}
        </Typo>
        <ul className="space-y-3">
          {COMPANY_MENU.map((it, idx) => (
            <li key={it.label + idx}>
              <Link to={it.href}>
                <Typo
                  tag={ETypoTag.SPAN}
                  variant={ETypoVariant.BODY_TITLE_16}
                  color={ETypoColor.FOOTER}
                >
                  {t(it.label)}
                </Typo>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    ),
    [category, t]
  );

  const renderSegmentMenu = useMemo(
    () => (
      <div className="space-y-6">
        <Typo
          className="font-bold"
          variant={ETypoVariant.BODY_TITLE_16}
          color={ETypoColor.FOOTER}
          tag={ETypoTag.P}
        >
          {t("footer.segments")}
        </Typo>
        <ul className="space-y-3">
          {SEGMENT_MENU.map((it, idx) => (
            <li key={it.label + idx}>
              <Link to={it.href} rel="noreferrer">
                <Typo
                  tag={ETypoTag.SPAN}
                  variant={ETypoVariant.BODY_TITLE_16}
                  color={ETypoColor.FOOTER}
                >
                  {t(it.label)}
                </Typo>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    ),
    [t]
  );

  const renderSections = useMemo(
    () =>
      !isEmpty(locations) &&
      locations
        ?.filter((location) => !isEmpty(location?.projects))
        ?.map((location, index) => (
          <div key={location?.id + index} className="flex flex-col gap-6">
            <Link
              key={location?.id + index}
              to={`${Slug.PROJECTS}?location=${location?.slug}`}
            >
              <Typo
                className="font-bold capitalize text-footerText hover:underline child:!text-header-16 child:!font-bold child:!text-footerText lg:!text-header-16"
                variant={ETypoVariant.HEADER_16}
                color={ETypoColor.FOOTER}
                tag={ETypoTag.DIV}
              >
                <div
                  dangerouslySetInnerHTML={{ __html: location?.name || "" }}
                  className="ql-editor"
                />
              </Typo>
            </Link>
            {!isEmpty(location?.projects) && (
              <div className="flex flex-col gap-2">
                {location.projects?.map((project, projectIndex) => (
                  <Link
                    key={project?.id + projectIndex}
                    to={`${Slug.PROJECTS}/${project?.slug || ""}`}
                  >
                    <Typo
                      className="!text-body-title-16 font-normal text-footerText hover:underline"
                      variant={ETypoVariant.BODY_TITLE_16}
                      color={ETypoColor.FOOTER}
                      tag={ETypoTag.P}
                    >
                      {project?.name || ""}
                    </Typo>
                  </Link>
                ))}
              </div>
            )}
          </div>
        )),
    [locations]
  );

  const renderContact = useMemo(
    () => (
      <div className="col-span-1 space-y-6 lg:col-span-3">
        <Typo
          className="font-bold"
          variant={ETypoVariant.BODY_TITLE_16}
          color={ETypoColor.FOOTER}
          tag={ETypoTag.P}
        >
          {t("footer.contact")}
        </Typo>
        <div className="flex flex-col items-start justify-between gap-6 lg:flex-row lg:items-center">
          <a href={`tel:${phoneNumber}`}>
            <Typo
              className="text-nowrap font-bold"
              variant={ETypoVariant.HEADER_20}
              color={ETypoColor.FOOTER}
              tag={ETypoTag.P}
            >
              {formatPhoneNumber(phoneNumber)}
            </Typo>
          </a>
          <div className="flex flex-row items-center gap-6">
            {socialLinks?.map((socialLink) => (
              <Link
                key={socialLink?.type}
                target="_blank"
                to={socialLink?.url || ""}
                rel="noreferrer"
              >
                <Icon
                  TypeIcon={
                    SOCIAL_LIGHT_ICONS?.[socialLink?.type as ESocialLinks] ||
                    FacebookLightIcon
                  }
                />
              </Link>
            ))}
          </div>
        </div>
      </div>
    ),
    [t, phoneNumber, socialLinks]
  );

  return (
    <footer className="mt-auto flex shrink-0 flex-col gap-12 bg-footerBackground py-12">
      <div
        className={cn(
          "container grid grid-cols-2 gap-x-[124px] gap-y-16 lg:gap-x-[80px] md:gap-x-[40px] sm:grid-cols-1 sm:px-6",
          isProjectDetailPage ? "lg:gap-y-6" : ""
        )}
      >
        <div className="flex flex-col gap-6">
          {logo && (
            <Link to={Slug.HOME} className="shrink-0">
              <ImageResponsive
                imageData={logo}
                alt="Brand Logo"
                className="h-[34px] w-auto origin-left"
                zoom={1.3}
              />
            </Link>
          )}
          <SalesTeamInfoFooter />
        </div>
        <div className="grid grid-cols-3 gap-6 lg:grid-cols-2 sm:gap-y-12">
          {renderCompanyMenu}
          {renderSegmentMenu}
          {renderContact}
        </div>
      </div>
      <div className="container">
        {disclaimer && (
          <div
            dangerouslySetInnerHTML={{ __html: disclaimer }}
            className={cn("ql-editor text-footerText")}
          />
        )}
        {siteDescription && (
          <div
            dangerouslySetInnerHTML={{ __html: siteDescription }}
            className={cn("ql-editor text-footerText")}
          />
        )}
      </div>
      {isProjectDetailPage && (
        <div className="container">
          <div className="grid grid-cols-4 gap-12 border-t border-t-footerBorder pt-12 lg:grid-cols-2 lg:gap-x-4">
            {renderSections}
          </div>
        </div>
      )}
      <div
        className={cn(
          "container flex flex-row items-center gap-12 sm:flex-col sm:items-start sm:gap-2 sm:px-6"
        )}
      >
        <Link to="/privacy-policy">
          <Typo
            className="underline"
            tag={ETypoTag.SPAN}
            variant={ETypoVariant.BODY_TITLE_16}
            color={ETypoColor.FOOTER}
          >
            {t("footer.privacy_policy")}
          </Typo>
        </Link>
        <Typo
          className="opacity-50"
          tag={ETypoTag.SPAN}
          variant={ETypoVariant.BODY_TITLE_16}
          color={ETypoColor.FOOTER}
        >
          {t("footer.copyright")} © {formattedYear} {domain}
        </Typo>
      </div>
    </footer>
  );
}
