import { useRouteLoaderData } from "@remix-run/react";
import { useTranslation } from "react-i18next";

import Typo from "~/components/atoms/Typo";
import { IRouteLoaderData } from "~/types/routeLoaderData";
import { ETypoColor } from "~/utilities/enums/Colors";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";

const SalesTeamInfoFooter = () => {
  const { t } = useTranslation();
  const { salesTeamInfo } = useRouteLoaderData("root") as IRouteLoaderData;
  return (
    <div className="flex flex-col gap-3">
      <div className="flex gap-1">
        <Typo
          variant={ETypoVariant.HEADER_18}
          tag={ETypoTag.SPAN}
          color={ETypoColor.FOOTER}
          className="font-bold"
        >
          {t("sales_team.heading")}
        </Typo>
      </div>
      <div className="flex gap-1">
        <Typo
          variant={ETypoVariant.HEADER_16}
          tag={ETypoTag.SPAN}
          color={ETypoColor.FOOTER}
        >
          {salesTeamInfo.name}
        </Typo>
      </div>
      <div className="flex gap-1">
        <Typo
          variant={ETypoVariant.HEADER_16}
          tag={ETypoTag.SPAN}
          color={ETypoColor.FOOTER}
        >
          {t("sales_team.cea_reg_no")}
        </Typo>
        <Typo
          variant={ETypoVariant.HEADER_16}
          tag={ETypoTag.SPAN}
          color={ETypoColor.FOOTER}
        >
          {salesTeamInfo.registrationNumber}
        </Typo>
      </div>
      <div className="flex gap-1">
        <Typo
          variant={ETypoVariant.HEADER_16}
          tag={ETypoTag.SPAN}
          color={ETypoColor.FOOTER}
        >
          {salesTeamInfo.currentEa}
        </Typo>
      </div>
      <div className="flex gap-1">
        <Typo
          variant={ETypoVariant.HEADER_16}
          tag={ETypoTag.SPAN}
          color={ETypoColor.FOOTER}
        >
          {t("sales_team.cea_license_no")}
        </Typo>
        <Typo
          variant={ETypoVariant.HEADER_16}
          tag={ETypoTag.SPAN}
          color={ETypoColor.FOOTER}
        >
          {salesTeamInfo.licenseNumber}
        </Typo>
      </div>
    </div>
  );
};

export default SalesTeamInfoFooter;
