import { PropsSVG } from "./types";

function MobilePhoneIcon({ width = 18, height = 18, ...props }: PropsSVG) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.53638 5.85505C4.90757 6.62818 5.41359 7.35279 6.05444 7.99363C6.69528 8.63447 7.41989 9.14049 8.19302 9.51169C8.25952 9.54362 8.29277 9.55958 8.33484 9.57185C8.48437 9.61543 8.66797 9.58413 8.7946 9.49345C8.83023 9.46794 8.86072 9.43745 8.92168 9.37649C9.10814 9.19003 9.20137 9.0968 9.29512 9.03585C9.64866 8.80599 10.1044 8.80599 10.458 9.03585C10.5517 9.0968 10.645 9.19003 10.8314 9.37649L10.9353 9.48042C11.2188 9.76385 11.3605 9.90557 11.4375 10.0578C11.5906 10.3605 11.5906 10.7179 11.4375 11.0206C11.3605 11.1729 11.2188 11.3146 10.9353 11.598L10.8513 11.6821C10.5688 11.9645 10.4276 12.1058 10.2356 12.2136C10.0225 12.3333 9.69155 12.4194 9.44717 12.4187C9.22693 12.418 9.07641 12.3753 8.77537 12.2898C7.15756 11.8307 5.63097 10.9643 4.35738 9.69068C3.08379 8.41709 2.2174 6.8905 1.75822 5.27269C1.67278 4.97165 1.63005 4.82113 1.6294 4.60089C1.62867 4.35651 1.71473 4.02558 1.83442 3.81251C1.94229 3.62049 2.08352 3.47926 2.36599 3.1968L2.45006 3.11272C2.73349 2.82929 2.87521 2.68757 3.02742 2.61059C3.33011 2.45748 3.68759 2.45748 3.99029 2.61059C4.14249 2.68757 4.28421 2.82929 4.56765 3.11272L4.67158 3.21665C4.85803 3.40311 4.95126 3.49634 5.01221 3.59009C5.24208 3.94363 5.24208 4.39941 5.01221 4.75295C4.95126 4.8467 4.85803 4.93992 4.67158 5.12638C4.61061 5.18735 4.58013 5.21783 4.55461 5.25346C4.46394 5.38009 4.43263 5.5637 4.47622 5.71322C4.48848 5.75529 4.50445 5.78854 4.53638 5.85505Z"
        fill="white"
      />
      <path
        d="M8.09375 3.79999C8.61468 3.90162 9.09342 4.15639 9.46872 4.53169C9.84401 4.90698 10.0988 5.38573 10.2004 5.90666M8.09375 1.66666C9.17603 1.78689 10.1853 2.27155 10.9557 3.04106C11.7262 3.81057 12.2122 4.8192 12.3337 5.90132"
        stroke="white"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default MobilePhoneIcon;
